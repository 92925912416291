.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background-image: url('~/media/img/BackGround_Solo.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  &.event {
    background-image: url('~/media/img/BackGround_Event.jpg');
  }

  &.not-on-air {
    background-image: url('~/media/img/BackGround_NotOnAir.jpg');
    background-position: center bottom;
  }
}
