@import '~/shared.scss';
@import '~/typography.scss';

.login-page {
  margin-top: 65px;
}

.title {
  display: flex;
  justify-content: center;
  margin: 20px -43px;
}

.title-image {
  width: 986px;
  height: 109px;
  @include text-image('~/media/img/titles/Password_recovery.png');
}

.bottom-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 55px;
  margin-bottom: 20px;
}

.bottom-link {
  margin-top: 10px;
  margin-bottom: 10px;
  color: rgba(#000, 0);
  font-size: 0;
  line-height: 0;
  @include text-image-hover();
}

.login-link {
  width: 140px;
  height: 52px;
  background-image: url('~/media/img/buttons/Login.png');
  background-size: 140px 52px;
}
