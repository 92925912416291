@import '~/_shared.scss';

.content-container {
  position: relative;
  width: 900px;

  @include media('<desktop') {
    width: auto;
  }
}
