@import '~/shared.scss';
@import '~/typography.scss';

.login-page {
  margin-top: 65px;
}

.title {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.title-image {
  width: 290px;
  height: 110px;
  @include text-image('~/media/img/titles/Login.png');
}

.bottom-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.bottom-link {
  margin-top: 10px;
  margin-bottom: 10px;
  color: rgba(#000, 0);
  font-size: 0;
  line-height: 0;
  @include text-image-hover();
}

.register-link {
  width: 136px;
  height: 26px;
  background-image: url('~/media/img/buttons/Register.png');
  background-size: 136px 26px;
}

.forget-link {
  width: 281px;
  height: 26px;
  background-image: url('~/media/img/buttons/Forget.png');
  background-size: 281px 26px;
}
