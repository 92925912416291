@import '~/shared.scss';

.login-form {
  margin-top: 33px;
}

.cta-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 145px;
}

.submit-button {
  width: 300px;
  height: 102px;
  margin-top: 15px;
  background-color: rgba(#000, 0);
  background-image: url('~/media/img/buttons/Submit.png');
  background-repeat: no-repeat;
  background-size: 300px 102px;
  border: 0;
  color: rgba(#000, 0);
  cursor: pointer;

  &[disabled] {
    opacity: 0.8;
    cursor: not-allowed;
  }

  // // @todo: implement hover with new image
  // &:not([disabled]):hover {
  //   background-image: url('~/media/img/buttons/Submit_overlay.png');
  // }
}
