@import '~/shared.scss';
@import '~/typography.scss';

.login-page {
  margin-top: 25px;
}

.title {
  display: flex;
  justify-content: center;
  margin: 20px -80px;
}

.title-image {
  width: 1030px;
  height: 144px;
  @include text-image('~/media/img/titles/Profile_modification.png');
}

.bottom-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.bottom-link {
  margin-top: 10px;
  margin-bottom: 10px;
  @include sans-serif($bold);
  color: #000;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 22px;

  &:hover {
    color: lighten(#000, 15%);
  }
}
