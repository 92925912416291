@import './reset';
@import './typography';
@import './normalize';

@font-face {
  font-family: 'CuteEnung';
  src: url('./media/fonts/CuteEnung/CuteEnung.woff2') format('woff2'),
    url('./media/fonts/CuteEnung/CuteEnung.woff') format('woff'),
    url('./media/fonts/CuteEnung/CuteEnung.ttf') format('truetype');
}

@font-face {
  font-family: 'BreakfastNoodlesRegular';
  src: url('./media/fonts/BreakfastNoodles/BreakfastNoodles-Regular.woff') format('woff'),
    url('./media/fonts/BreakfastNoodles/BreakfastNoodles-Regular.ttf') format('truetype');
}

body {
  overflow-y: scroll;
}
