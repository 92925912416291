// IMPORTANT NOTE: This file not for any kind of styles!
// It should contain only SCSS staff that will produce 0 bytes in final css file.
// This shared module is included to ALL SCSS chunks to re-use variables, mixins, etc.
@import 'bourbon';
@import 'include-media';

$color-red: #b61818;
$color-sky-blue: #5debec;

$breakpoints: (
  phone: 320px,
  tablet: 768px,
  desktop: 1280px,
  wide: 1440px,
);

@mixin text-image($url) {
  line-height: 0;
  font-size: 0;
  background-image: url($url);
  background-repeat: no-repeat;
  color: rgba(#000, 0);
}

@mixin text-image-hover($op: 1) {
  &:hover {
    filter: drop-shadow(0px 0px 7px rgba(white, $op));
  }
}

@mixin acid-background($op: 1) {
  background: #abd3b0;
  background: linear-gradient(
    90deg,
    rgba(#abd3b0, $op) 0%,
    rgba(#7bb9e5, $op) 56%,
    rgba(#415969, $op) 86%,
    rgba(#212e40, $op) 100%
  );
}

@mixin dark-background($op: 1) {
  background: #496775;
  background: linear-gradient(
    90deg,
    rgba(#496775, $op) 0%,
    rgba(#1b4350, $op) 56%,
    rgba(#072736, $op) 86%,
    rgba(#000416, $op) 100%
  );
}
