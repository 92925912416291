@import '~/_shared.scss';
@import '~/_typography.scss';

.game-page {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  width: 100%;

  :global(#__ReactUnityWebGL_1__) {
    z-index: 1;
  }
}

.left-bar {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 13px 29px 20px;
}

.score {
  margin-bottom: 20px;
  padding-right: 10px;
  width: 175px;
  background-repeat: no-repeat;
  background-position: top right;
  @include font-cute();
  color: white;
  text-align: right;
  font-size: 15px;

  &:nth-child(1) {
    padding-top: 93px;
    background-image: url('~/media/img/BestRankedPlayer.png');
  }

  &:nth-child(2) {
    padding-top: 41px;
    background-image: url('~/media/img/2nd3rd.png');
  }

  &:nth-child(3) {
    padding-top: 41px;
    background-image: url('~/media/img/4th-5th.png');
  }
}

.score-link {
  display: block;
  width: 120px;
  height: 42px;
  background-size: 120px 42px;
  background-position: top right;
  @include text-image('~/media/img/buttons/Scores.png');
  cursor: pointer;

  &:hover {
    @include text-image('~/media/img/buttons/Scores_overlay.png');
  }
}

.controls {
  padding: 43px 29px;
}

.control {
  width: 133px;
  height: 78px;
  margin-bottom: 20px;

  &.jump {
    @include text-image('~/media/img/Ztojump.png');
  }

  &.slide {
    @include text-image('~/media/img/Stoslide.png');
  }

  &.attack {
    @include text-image('~/media/img/SPACEtoattack.png');
  }
}

.game-wrapper {
  position: relative;
}

.game-loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(black, 0.5);
}
