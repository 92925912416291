@import '~/shared.scss';
@import '~/typography.scss';

.not-on-air-page {
  position: relative;
  max-width: 1024px;
  margin-top: 40px;
  padding: 0 10px;
  color: white;
  text-shadow: 0 0 10px black;
  line-height: 1.5;
  @include font-breakfast();
  text-align: center;
}

.header {
  margin-bottom: 30px;
}

.havana-link {
  img {
    width: 120px;
  }
}

.text {
  font-size: 72px;
  margin-bottom: 30px;
}

.timer {
  font-size: 100px;
  margin-bottom: 30px;
  
  span {
    display: inline-block;
    width: .6em;
    text-align: center;
  }
}

.outcome-link {
  color: $color-sky-blue;
}