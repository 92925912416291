@import '~/_shared.scss';

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: auto;
  padding-bottom: 15px;
}

.bottom-link {
  display: inline-block;
  width: 160px;
  height: 32px;
  margin-bottom: 10px;
  @include text-image('~/media/img/titles/Home_page.png');
  background-size: contain;
  background-position: center center;
  @include text-image-hover(0.5);
}

.logo {
  height: 104px;
  width: 100%;
  margin-bottom: 45px;
  background-image: url('~/media/img/LogoHavana.png');
  background-repeat: no-repeat;
  background-position: center center;

  &.event {
    background-image: url('~/media/img/LogoHavanarouge.png');
  }
}

.footer-menu {
  font-size: 15px;
  color: white;
  text-align: center;
}

.menu-link {
  padding: 10px 5px;
  color: white;
  text-decoration: none;
}
