@import '~/_shared.scss';

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.logo {
  margin-top: 75px;
  margin-bottom: 10px;

  @include media('<desktop') {
    a {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      width: calc(100% - 40px);
    }
  }
}

.user-menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
  right: 110px;
}

.name {
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: -1px;

  a {
    color: white;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.button-image {
  display: block;
  padding: 0;
  border: 0;
  background-color: rgba(#000, 0);
  background-repeat: no-repeat;
  color: rgba(#000, 0);
  cursor: pointer;
}

.disconnect-button {
  width: 136px;
  height: 47px;
  background-image: url('~/media/img/buttons/Disconnect.png');
  background-size: 136px 47px;

  &:hover {
    background-image: url('~/media/img/buttons/Disconnect_overlay.png');
  }
}

.connect-button {
  width: 136px;
  height: 47px;
  margin-right: 20px;
  background-image: url('~/media/img/buttons/Connect.png');
  background-size: 136px 47px;

  &:hover {
    background-image: url('~/media/img/buttons/Connect_overlay.png');
  }
}

.register-button {
  width: 136px;
  height: 26px;
  margin-right: 20px;
  background-image: url('~/media/img/buttons/Register.png');
  background-size: 136px 26px;
  @include text-image-hover(0.3);
}
