@import '~/_shared.scss';
@import '~/_typography.scss';

$leaderboard-height: 610px;
$header-height: 83px;

.leaderboard-page {
  display: grid;
  grid-template-columns: 1fr 900px 1fr;
  width: 100%;
  position: relative;
  color: #000135;
}

.left-bar {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 13px 29px 20px;
}

.score {
  margin-bottom: 20px;
  padding-right: 10px;
  width: 175px;
  background-repeat: no-repeat;
  background-position: top right;
  @include font-cute();
  color: white;
  text-align: right;
  font-size: 15px;

  &:nth-child(1) {
    padding-top: 93px;
    background-image: url('~/media/img/BestRankedPlayer.png');
  }

  &:nth-child(2) {
    padding-top: 41px;
    background-image: url('~/media/img/2nd3rd.png');
  }

  &:nth-child(3) {
    padding-top: 41px;
    background-image: url('~/media/img/4th-5th.png');
  }
}

.leaderboard {
  position: relative;
  height: $leaderboard-height;
  margin-top: 10px;
  @include acid-background(0.5);

  :global(.simplebar-track.simplebar-vertical) {
    width: 20px;
    margin-bottom: 20px;
    margin-right: 5px;
    @include dark-background();
    border-radius: 10px;
  }

  :global(.simplebar-scrollbar.simplebar-visible) {
    &::before {
      box-shadow: inset -1px 3px 8px 5px #82abbe;
    }
  }
}

.leaderboard-header {
  display: flex;
  height: $header-height;
  margin-right: 30px;
  justify-content: space-between;
  align-items: flex-end;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
}

.rank-header {
  min-width: 80px;
  padding: 10px 40px 30px;
}

.player-header {
  min-width: 180px;
  padding: 10px 40px;
  display: inline-block;
  width: 175px;
  height: 63px;
  @include text-image('~/media/img/Player.png');
  background-position: center;
}

.score-header {
  min-width: 180px;
  padding: 10px 40px;
  display: inline-block;
  width: 175px;
  height: 63px;
  @include text-image('~/media/img/Score.png');
  background-position: center;
}

.score-list-wrapper {
  height: $leaderboard-height - $header-height;
  width: 100%;
}

.score-list {
  position: relative;
  width: 100%;
  border-collapse: collapse;
  margin: 10px 0 10px;
  font-weight: 700;
  font-size: 30px;

  td {
    padding: 5px 20px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    vertical-align: middle;
  }
}

.row-data {
  td {
    height: 41px;
  }
}

.link-game {
  display: block;
  margin: 10px auto 0;
  @include text-image('~/media/img/buttons/Play_again.png');
  width: 390px;
  height: 80px;
  background-size: 390px 80px;
  @include text-image-hover();
}
