@import '~/shared.scss';
@import '~/typography.scss';

.create-account-page {
  margin-top: 35px;
}

.title {
  display: flex;
  justify-content: center;
  margin: 20px -12px;
}

.title-image {
  width: 924px;
  height: 110px;
  @include text-image('~/media/img/titles/User_registration.png');
}

.bottom-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.bottom-link {
  margin-top: 10px;
  margin-bottom: 10px;
  @include sans-serif($bold);
  color: #000;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 22px;

  &:hover {
    color: lighten(#000, 15%);
  }
}

.login-link {
  width: 114px;
  height: 32px;
  @include text-image('~/media/img/buttons/Login_sm.png');
  background-size: 114px 32px;
  background-position: center center;
  @include text-image-hover();
}

.forget-link {
  width: 281px;
  height: 26px;
  @include text-image('~/media/img/buttons/Forget.png');
  background-size: 281px 26px;
  @include text-image-hover();
}
