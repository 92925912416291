@import '~/_shared.scss';

.info-page {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 85px;
  width: 100%;
}

.info-text {
  padding: 20px 40px;
  @include acid-background(0.5);
  color: white;
  font-size: 22px;
  font-weight: 700;
  min-width: 300px;
  max-width: 485px;
  line-height: 1.25;

  @include media('<desktop') {
    margin: 20px;
  }

  a {
    color: white;
  }
}
