@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

$fallback-sans-serif: Arial, Helvetica, sans-serif;

$sans-serif: 'Open Sans', $fallback-sans-serif;
$font-cute: 'CuteEnung', $fallback-sans-serif;
$font-breakfast: 'BreakfastNoodlesRegular', 'CuteEnung', $fallback-sans-serif;

$light: 300;
$regular: 400;
$medium: 500;
$bold: 700;
$black: 900;

@mixin sans-serif($weight: $regular, $style: normal) {
  font-family: $sans-serif;
  font-style: $style;
  font-weight: $weight;
}

@mixin font-cute($weight: $regular, $style: normal) {
  font-family: $font-cute;
  font-style: $style;
  font-weight: $weight;
}

@mixin font-breakfast($weight: $regular, $style: normal) {
  font-family: $font-breakfast;
  font-style: $style;
  font-weight: $weight;
  text-rendering: optimizeLegibility;
}
