@import '~/shared.scss';

.homepage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 120px;
  margin-bottom: 20px;
}

.link-game {
  margin-bottom: 100px;
  @include text-image('~/media/img/titles/Game.png');
  width: 280px;
  height: 110px;
  background-size: 280px 110px;
  @include text-image-hover();
}

.link-leaderboard {
  margin-bottom: 130px;
  @include text-image('~/media/img/titles/Global_leaderboard.png');
  width: 790px;
  height: 91px;
  background-size: 790px 91px;
  @include text-image-hover(0.5);
}
