@import '~/shared.scss';

.select {
  position: relative;
  display: block;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 15px;
  border: 0;
  padding: 1px 6px;
  @include acid-background();
  box-shadow: 5px 5px 10px 0px rgba(black, 0.75);

  &::-webkit-input-placeholder {
    color: black;
  }

  &::-moz-placeholder {
    color: black;
  }
}
