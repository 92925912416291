@import '~/shared.scss';

.login-form {
  margin-top: 33px;
}

.cta-wrapper {
  display: flex;
  justify-content: center;
}

.connect-button {
  width: 280px;
  height: 98px;
  margin-top: 15px;
  background-color: rgba(#000, 0);
  background-image: url('~/media/img/buttons/Connect.png');
  background-repeat: no-repeat;
  background-size: 280px 98px;
  border: 0;
  color: rgba(#000, 0);
  cursor: pointer;

  &[disabled] {
    opacity: 0.8;
    cursor: not-allowed;
  }

  &:not([disabled]):hover {
    background-image: url('~/media/img/buttons/Connect_overlay.png');
  }
}

.submit-error {
  font-weight: 700;
  color: $color-red;
  text-shadow: 0px 0px 15px white;
}
